import React, { createContext, useState, useContext, useEffect } from "react";
import {
  getActiveLanguageLocal,
  setActiveLanguageLocal,
} from "../ulti/function";

// Create a context for the language
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [activeLanguage, setActiveLanguage] = useState(
    getActiveLanguageLocal()
  );

  useEffect(() => {
    setActiveLanguageLocal(activeLanguage);
  }, [activeLanguage]);

  return (
    <LanguageContext.Provider value={{ activeLanguage, setActiveLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
