import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import HOUSESBYMAP from "../graphql/house-by-map";
import HeartDefault from "../assets/img/heart_default.svg";
import HeartActive from "../assets/img/heart_active.svg";
import { getInforUser, getStatusLogin } from "../ulti/function";
import REMOVE_WISHLIST from "../graphql/remove-wishlist";
import ADD_WISHLIST from "../graphql/add-wishlist";
import FormFind from "../global/FormFind";
import DefaultImgHouse from "../assets/img/e2bf3f6943481d3ecd5ae3e7061641b6.png";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const mapOptions = {
  fullscreenControl: true,
  streetViewControl: true,
  mapTypeControl: true,
  zoomControl: true,
  scrollwheel: true,
  disableDefaultUI: false,
  gestureHandling: "greedy",
  mapTypeId: "roadmap",
  styles: [],
};
const MapCustom = ({ dataGlobal, uri }) => {
  const location = useLocation();
  const statusLogin = getStatusLogin();
  const navigate = useNavigate();
  const inforUser = getInforUser();
  const [dataListWishlist, setDataListWishlist] = useState([]);
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    if (!statusLogin) {
      navigate("/login");
    }
    window.scrollTo(0, 0);
  }, [statusLogin, navigate]);
  const [map, setMap] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const { lat, lng, price, inputValue, designs } = location.state || {};
  const [center, setCenter] = useState({
    lat: location.state ? location.state.lat : 21.5774647,
    lng: location.state ? location.state.lng : 105.6475984,
  });
  useEffect(() => {
    setCenter({
      lat: lat,
      lng: lng,
    });
  }, [lat, lng]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: dataGlobal
      ? dataGlobal.messageFormACF.contentMessageForm.ggMapKey
      : "AIzaSyCOJTATNUYyc0o5sCsPLwxjwmGuhRJLE5Y",
  });

  const formatPrice = (value) => {
    return `${(parseFloat(value) / 1000).toFixed(0)}K`;
  };
  function convertToSlug(title) {
    return title
      .toLowerCase() // Chuyển tất cả các chữ thành chữ thường
      .normalize("NFD") // Chuẩn hóa các ký tự có dấu
      .replace(/[\u0300-\u036f]/g, "") // Loại bỏ các dấu
      .replace(/đ/g, "d") // Thay thế 'đ' bằng 'd'
      .replace(/[^a-z0-9\s-]/g, "") // Loại bỏ tất cả các ký tự không phải chữ cái, số và khoảng trắng
      .trim() // Loại bỏ khoảng trắng thừa ở đầu và cuối chuỗi
      .replace(/\s+/g, "-") // Thay thế khoảng trắng bằng dấu gạch ngang
      .replace(/-+/g, "-"); // Loại bỏ dấu gạch ngang liên tiếp
  }
  const [
    removeWishlist,
    { loading: removeWishlistLoading, error: removeWishlistError },
  ] = useMutation(REMOVE_WISHLIST, {
    onCompleted: async (data) => {
      // await refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const [addWishlist, { loading: wishlistLoading, error: wishlistError }] =
    useMutation(ADD_WISHLIST, {
      onCompleted: async (data) => {
        try {
          // await refetch();
        } catch (refetchError) {
          console.log("Error during refetch:", refetchError);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const handleWishlist = async (id) => {
    if (!statusLogin) {
      navigate("/login");
    } else {
      try {
        await addWishlist({
          variables: {
            input: {
              userId: inforUser.userId,
              houseId: parseInt(id),
            },
          },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const handleRemoveWishlist = async (id) => {
    if (!statusLogin) {
      navigate("/login");
    } else {
      try {
        await removeWishlist({
          variables: {
            input: {
              userId: inforUser.userId,
              houseId: parseInt(id),
            },
          },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const slugArray = designs ? designs.map(convertToSlug) : [];
  const { loading, error, refetch } = useQuery(HOUSESBYMAP, {
    variables: {
      input: inforUser && inforUser.userId,
      address: inputValue,
      maxPrice: price,
      typeDesign: slugArray,
      idPage: uri,
    },
    onCompleted: (data) => {
      setDataList(data.housesNearby);
      setDataListWishlist(data.wishlistHouses);
    },
    onError: (error) => {
      console.log("Query error:", error);
    },
  });

  const onLoad = useCallback(function callback(map) {
    setMap(map);
    setMapInstance(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerClick = (index) => {
    if (activeMarker === index) {
      setActiveMarker(null);
    } else {
      const markerPosition = dataList[index].location;
      setCenter(markerPosition);
      setActiveMarker(index);
    }
  };

  const [processingId, setProcessingId] = useState(null);

  const handleWishlistClick = (id, isWishlisted) => {
    setProcessingId(id);
    const buttonElement = document.querySelector(`[data-id="${id}"]`);
    if (buttonElement.classList.contains("wishlist-icon")) {
      buttonElement.classList.remove("wishlist-icon");
    } else {
      buttonElement.classList.add("wishlist-icon");
    }
    if (isWishlisted) {
      handleRemoveWishlist(id).finally(() => setProcessingId(null));
    } else {
      handleWishlist(id).finally(() => setProcessingId(null));
    }
  };
  const [active, setActive] = useState(location.state?.active || false);
  const handleActive = () => {
    setActive(!active);
  };
  const createSvgMarker = (color) => {
    const svg = `
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
      >
        <defs>
          <style>
            .cls-1{fill:none;stroke:${
              color ? color : "#3b1600"
            };stroke-miterlimit:10;stroke-width:1.87px;}
          </style>
        </defs>
        <path
          class="cls-1"
          d="M9.22,21.33S1.75,14.8,1.75,9.2a7.47,7.47,0,1,1,14.93,0"
        />
        <polyline
          class="cls-1"
          points="20.42 15.73 20.42 22.27 11.08 22.27 11.08 15.73"
        />
        <line class="cls-1" x1="15.75" y1="18.53" x2="15.75" y2="22.27" />
        <circle class="cls-1" cx="9.22" cy="9.2" r="2.8" />
        <polyline
          class="cls-1"
          points="8.75 18.07 15.75 11.07 22.75 18.07"
        />
      </svg>
    `;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
  };
  const [mapInstance, setMapInstance] = useState(null);
  const [zoom, setZoom] = useState(11);
  const handleZoomChange = () => {
    if (mapInstance) {
      const currentZoom = mapInstance.getZoom();
      setZoom(currentZoom);
    }
  };
  useEffect(() => {
    if (mapInstance) {
      const zoomChangeListener = mapInstance.addListener(
        "zoom_changed",
        handleZoomChange
      );
      return () => zoomChangeListener.remove();
    }
  }, [mapInstance]);
  const getAdjustedPosition = (position, zoom) => {
    const scale = Math.pow(2, zoom); // Tính toán tỷ lệ zoom
    const offsetLat = 50 / scale; // Điều chỉnh giá trị lat dựa trên tỷ lệ zoom
    const offsetLng = 5 / scale; // Điều chỉnh giá trị lng dựa trên tỷ lệ zoom

    return {
      lat: position.lat + offsetLat,
      lng: position.lng - offsetLng,
    };
  };
  if (loading)
    return (
      <div className="load">
        <span className="loader"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;
  return isLoaded ? (
    <div className="gg-custom">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center} // Update center to reflect current state
        zoom={zoom} // Default zoom level
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={mapOptions}
      >
        <div className="form-active-map">
          {active && (
            <FormFind
              dataGlobal={dataGlobal}
              bgColor={"transparent"}
              checkHome={false}
            />
          )}
        </div>
        <div
          onClick={handleActive}
          className={`btn-mapsearch btn-filter ${
            active ? "active-search-form" : ""
          }`}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5728 30.3334C13.9328 30.3334 13.2928 30.1734 12.7061 29.8534C11.5195 29.1867 10.8127 28 10.8127 26.6534V19.5201C10.8127 18.8401 10.3727 17.84 9.95939 17.32L4.89274 12C4.05274 11.16 3.39941 9.69336 3.39941 8.61336V5.52006C3.39941 3.37339 5.02611 1.69336 7.09277 1.69336H24.8794C26.9194 1.69336 28.5728 3.34672 28.5728 5.38672V8.34672C28.5728 9.74672 27.7328 11.36 26.9328 12.1467C26.5461 12.5334 25.9061 12.5334 25.5195 12.1467C25.1328 11.76 25.1328 11.12 25.5195 10.7334C26.0128 10.24 26.5728 9.13339 26.5728 8.34672V5.38672C26.5728 4.45339 25.8127 3.69336 24.8794 3.69336H7.09277C6.14611 3.69336 5.39941 4.49339 5.39941 5.52006V8.61336C5.39941 9.1067 5.79942 10.0801 6.31942 10.6001L11.4528 16C12.1328 16.84 12.7994 18.2534 12.7994 19.5201V26.6534C12.7994 27.5334 13.3994 27.96 13.6661 28.1067C14.2394 28.4267 14.9195 28.4134 15.4528 28.0934L17.3194 26.8934C17.7061 26.6667 18.0794 25.9334 18.0794 25.44C18.0794 24.8934 18.5328 24.44 19.0794 24.44C19.6261 24.44 20.0794 24.8934 20.0794 25.44C20.0794 26.64 19.3327 28.0134 18.3861 28.5867L16.5328 29.7867C15.9328 30.1467 15.2528 30.3334 14.5728 30.3334Z"
              fill="url(#paint0_linear_1356_2736)"
            ></path>
            <path
              d="M21.4268 23.0265C18.5202 23.0265 16.1602 20.6665 16.1602 17.7598C16.1602 14.8532 18.5202 12.4932 21.4268 12.4932C24.3335 12.4932 26.6935 14.8532 26.6935 17.7598C26.6935 20.6665 24.3335 23.0265 21.4268 23.0265ZM21.4268 14.4932C19.6268 14.4932 18.1602 15.9598 18.1602 17.7598C18.1602 19.5598 19.6268 21.0265 21.4268 21.0265C23.2268 21.0265 24.6935 19.5598 24.6935 17.7598C24.6935 15.9598 23.2268 14.4932 21.4268 14.4932Z"
              fill="url(#paint1_linear_1356_2736)"
            ></path>
            <path
              d="M26.493 23.8271C26.2397 23.8271 25.9864 23.7337 25.7864 23.5337L24.4531 22.2004C24.0664 21.8137 24.0664 21.1737 24.4531 20.7871C24.8398 20.4004 25.4797 20.4004 25.8664 20.7871L27.1997 22.1204C27.5864 22.5071 27.5864 23.1471 27.1997 23.5337C27.0131 23.7204 26.7464 23.8271 26.493 23.8271Z"
              fill="url(#paint2_linear_1356_2736)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_1356_2736"
                x1="3.39941"
                y1="16.0134"
                x2="24.1453"
                y2="25.5973"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1541DF"></stop>
                <stop offset="1" stopColor="#0E30A7"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear_1356_2736"
                x1="16.1602"
                y1="17.7598"
                x2="24.4136"
                y2="22.0977"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1541DF"></stop>
                <stop offset="1" stopColor="#0E30A7"></stop>
              </linearGradient>
              <linearGradient
                id="paint2_linear_1356_2736"
                x1="24.1631"
                y1="22.1621"
                x2="26.7708"
                y2="23.5313"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1541DF"></stop>
                <stop offset="1" stopColor="#0E30A7"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
        {zoom > 19 ? (
          dataList.map((item, index) => {
            const isWishlisted = dataListWishlist.some(
              (wishlist) => parseInt(wishlist.id) === parseInt(item.id)
            );
            const svgIcon = createSvgMarker(item.pinColor);
            return (
              <React.Fragment key={item.id}>
                <Marker
                  icon={svgIcon}
                  position={item.location}
                  onClick={() => handleMarkerClick(index)}
                />
                {activeMarker === index && (
                  <InfoWindow
                    position={getAdjustedPosition(item.location, map.getZoom())}
                    onCloseClick={() => setActiveMarker(null)}
                  >
                    <div className="item-apatto" style={{ width: "250px" }}>
                      <div className="wrap-item">
                        <Link to={"/house/" + item.slug}>
                          <div className="img-featured">
                            <img
                              src={
                                item.featuredImage
                                  ? item.featuredImage
                                  : DefaultImgHouse
                              }
                              alt="Logo"
                              loading="lazy"
                              className="logo-footer"
                              width="626"
                              height="418"
                            />
                            <div
                              data-id={item.id}
                              onClick={() =>
                                handleWishlistClick(item.id, isWishlisted)
                              }
                              className={`btn-wishlist ${
                                isWishlisted ? "wishlist-icon" : ""
                              }`}
                            >
                              {removeWishlistLoading || wishlistLoading ? (
                                <span className="loader load-ajax"></span>
                              ) : (
                                <>
                                  <img
                                    loading="lazy"
                                    className="heart"
                                    style={{ width: "24px" }}
                                    src={HeartDefault}
                                    alt="heart"
                                  />
                                  <img
                                    loading="lazy"
                                    className="heart heart-active"
                                    style={{ width: "28px" }}
                                    src={HeartActive}
                                    alt="heart"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <h3 className="title">{item.houseCode}</h3>
                          <div className="address">
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.00002 1.78516C9.47765 1.78516 10.8948 2.37214 11.9396 3.41699C12.9845 4.46184 13.5714 5.87895 13.5714 7.35658C13.5714 9.71087 11.9172 12.2766 8.65145 15.0812C8.46986 15.2371 8.23834 15.3228 7.99895 15.3226C7.75956 15.3224 7.52819 15.2363 7.34688 15.08L7.13087 14.8926C4.00973 12.1612 2.42859 9.6583 2.42859 7.35658C2.42859 5.87895 3.01558 4.46184 4.06042 3.41699C5.10527 2.37214 6.52238 1.78516 8.00002 1.78516ZM8.00002 5.21373C7.4317 5.21373 6.88665 5.43949 6.48479 5.84136C6.08293 6.24322 5.85716 6.78826 5.85716 7.35658C5.85716 7.92491 6.08293 8.46995 6.48479 8.87181C6.88665 9.27368 7.4317 9.49944 8.00002 9.49944C8.56834 9.49944 9.11338 9.27368 9.51525 8.87181C9.91711 8.46995 10.1429 7.92491 10.1429 7.35658C10.1429 6.78826 9.91711 6.24322 9.51525 5.84136C9.11338 5.43949 8.56834 5.21373 8.00002 5.21373Z"
                                fill="url(#paint0_linear_396_4678)"
                              ></path>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_396_4678"
                                  x1="13.5714"
                                  y1="1.78516"
                                  x2="1.21276"
                                  y2="3.5274"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#0193FD"></stop>
                                  <stop offset="1" stopColor="#133DD3"></stop>
                                </linearGradient>
                              </defs>
                            </svg>
                            <span className="text">{item.address}</span>
                          </div>
                          <div className="eki">
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.33333 1.83398C10.0175 1.83398 10.6755 2.09694 11.1712 2.56847C11.6669 3.03999 11.9625 3.68401 11.9967 4.36732L12 4.50065V12.5007C12 13.1848 11.737 13.8428 11.2655 14.3385C10.794 14.8342 10.15 15.1298 9.46667 15.164L9.33333 15.1673H6.66667C5.98251 15.1673 5.32451 14.9044 4.82879 14.4328C4.33307 13.9613 4.03754 13.3173 4.00333 12.634L4 12.5007V4.50065C4 3.81649 4.26296 3.1585 4.73448 2.66278C5.20601 2.16705 5.85003 1.87153 6.53333 1.83732L6.66667 1.83398H9.33333ZM8 10.5007C7.64638 10.5007 7.30724 10.6411 7.05719 10.8912C6.80714 11.1412 6.66667 11.4804 6.66667 11.834C6.66667 12.1876 6.80714 12.5267 7.05719 12.7768C7.30724 13.0268 7.64638 13.1673 8 13.1673C8.35362 13.1673 8.69276 13.0268 8.94281 12.7768C9.19286 12.5267 9.33333 12.1876 9.33333 11.834C9.33333 11.4804 9.19286 11.1412 8.94281 10.8912C8.69276 10.6411 8.35362 10.5007 8 10.5007ZM8 5.83398C7.37016 5.83398 6.76967 6.04897 6.28159 6.46356C5.79351 6.87814 5.50596 7.41194 5.44899 7.97248L5.44448 8.08398H10.5556L10.5511 7.97248C10.4941 7.41194 10.2066 6.87814 9.71853 6.46356C9.23045 6.04897 8.62984 5.83398 8 5.83398Z"
                                fill="url(#paint0_linear_396_4679)"
                              ></path>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_396_4679"
                                  x1="12"
                                  y1="1.83398"
                                  x2="1.21276"
                                  y2="3.5274"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#0193FD"></stop>
                                  <stop offset="1" stopColor="#133DD3"></stop>
                                </linearGradient>
                              </defs>
                            </svg>
                            <span className="text">{item.traffic}</span>
                          </div>
                          <div className="eki">
                                <svg
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.2422 11.6125C14.2366 11.597 14.228 11.5827 14.2169 11.5705C14.2057 11.5583 14.1923 11.5484 14.1773 11.5415C14.1624 11.5345 14.1462 11.5305 14.1297 11.5299C14.1132 11.5292 14.0967 11.5318 14.0813 11.5375L13.125 11.8859V3.47656C13.125 2.88906 12.65 2.41406 12.0625 2.41406H3.9375C3.35 2.41406 2.875 2.88906 2.875 3.47656V11.8859L1.91719 11.5375C1.90312 11.5328 1.88906 11.5297 1.875 11.5297C1.80625 11.5297 1.75 11.5859 1.75 11.6547V12.5859C1.75 12.6375 1.78281 12.6844 1.83281 12.7031L7.82812 14.8844C7.93906 14.925 8.05938 14.925 8.17031 14.8844L14.1672 12.7047C14.2172 12.6859 14.25 12.6391 14.25 12.5875V11.6562C14.25 11.6406 14.2469 11.6266 14.2422 11.6125ZM10.4328 5.72188L8.8125 8.71562H9.7125C9.78125 8.71562 9.8375 8.77187 9.8375 8.84062V9.26406C9.8375 9.33281 9.78125 9.38906 9.7125 9.38906H8.52031V9.99844H9.7125C9.78125 9.99844 9.8375 10.0547 9.8375 10.1234V10.5469C9.8375 10.6156 9.78125 10.6719 9.7125 10.6719H8.52031V11.6641C8.52031 11.7328 8.46406 11.7891 8.39531 11.7891H7.61562C7.54688 11.7891 7.49062 11.7328 7.49062 11.6641V10.6734H6.30312C6.23438 10.6734 6.17812 10.6172 6.17812 10.5484V10.125C6.17812 10.0563 6.23438 10 6.30312 10H7.49062V9.39062H6.30312C6.23438 9.39062 6.17812 9.33437 6.17812 9.26562V8.84219C6.17812 8.77344 6.23438 8.71719 6.30312 8.71719H7.19375L5.57031 5.72344C5.5375 5.66406 5.55938 5.5875 5.62031 5.55469C5.63906 5.54375 5.65937 5.53906 5.67969 5.53906H6.55C6.57303 5.53919 6.59558 5.54568 6.61515 5.55781C6.63473 5.56994 6.65057 5.58725 6.66094 5.60781L7.98438 8.24531H8.03594L9.35938 5.60781C9.37969 5.56562 9.42344 5.53906 9.47031 5.53906H10.3219C10.3906 5.53906 10.4469 5.59531 10.4484 5.6625C10.4484 5.68281 10.4422 5.70313 10.4328 5.72188Z"
                                    fill="url(#paint0_linear_871_5225)"
                                  />
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_871_5225"
                                      x1="1.75"
                                      y1="8.66445"
                                      x2="11.5447"
                                      y2="13.8121"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#1541DF" />
                                      <stop offset="1" stop-color="#0E30A7" />
                                    </linearGradient>
                                  </defs>
                                </svg>
                                <span className="text">{item.price}</span>
                              </div>
                        </Link>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </React.Fragment>
            );
          })
        ) : (
          <MarkerClusterer onClick={() => setActiveMarker(null)}>
            {(clusterer) =>
              dataList.map((item, index) => {
                const isWishlisted = dataListWishlist.some(
                  (wishlist) => parseInt(wishlist.id) === parseInt(item.id)
                );
                const svgIcon = createSvgMarker(item.pinColor);
                return (
                  <React.Fragment key={item.id}>
                    <Marker
                      icon={svgIcon}
                      position={item.location}
                      clusterer={clusterer}
                      onClick={() => handleMarkerClick(index)}
                    />
                    {activeMarker === index && (
                      <InfoWindow
                        position={getAdjustedPosition(
                          item.location,
                          map.getZoom()
                        )}
                        onCloseClick={() => setActiveMarker(null)}
                      >
                        <div className="item-apatto" style={{ width: "250px" }}>
                          <div className="wrap-item">
                            <Link to={"/house/" + item.slug}>
                              <div className="img-featured">
                                <img
                                  src={
                                    item.featuredImage
                                      ? item.featuredImage
                                      : DefaultImgHouse
                                  }
                                  alt="Logo"
                                  loading="lazy"
                                  className="logo-footer"
                                  width="626"
                                  height="418"
                                />
                                <div
                                  data-id={item.id}
                                  onClick={() =>
                                    handleWishlistClick(item.id, isWishlisted)
                                  }
                                  className={`btn-wishlist ${
                                    isWishlisted ? "wishlist-icon" : ""
                                  }`}
                                >
                                  {removeWishlistLoading || wishlistLoading ? (
                                    <span className="loader load-ajax"></span>
                                  ) : (
                                    <>
                                      <img
                                        loading="lazy"
                                        className="heart"
                                        style={{ width: "24px" }}
                                        src={HeartDefault}
                                        alt="heart"
                                      />
                                      <img
                                        loading="lazy"
                                        className="heart heart-active"
                                        style={{ width: "28px" }}
                                        src={HeartActive}
                                        alt="heart"
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                              <h3 className="title">{item.houseCode}</h3>
                              <div className="address">
                                <svg
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.00002 1.78516C9.47765 1.78516 10.8948 2.37214 11.9396 3.41699C12.9845 4.46184 13.5714 5.87895 13.5714 7.35658C13.5714 9.71087 11.9172 12.2766 8.65145 15.0812C8.46986 15.2371 8.23834 15.3228 7.99895 15.3226C7.75956 15.3224 7.52819 15.2363 7.34688 15.08L7.13087 14.8926C4.00973 12.1612 2.42859 9.6583 2.42859 7.35658C2.42859 5.87895 3.01558 4.46184 4.06042 3.41699C5.10527 2.37214 6.52238 1.78516 8.00002 1.78516ZM8.00002 5.21373C7.4317 5.21373 6.88665 5.43949 6.48479 5.84136C6.08293 6.24322 5.85716 6.78826 5.85716 7.35658C5.85716 7.92491 6.08293 8.46995 6.48479 8.87181C6.88665 9.27368 7.4317 9.49944 8.00002 9.49944C8.56834 9.49944 9.11338 9.27368 9.51525 8.87181C9.91711 8.46995 10.1429 7.92491 10.1429 7.35658C10.1429 6.78826 9.91711 6.24322 9.51525 5.84136C9.11338 5.43949 8.56834 5.21373 8.00002 5.21373Z"
                                    fill="url(#paint0_linear_396_4678)"
                                  ></path>
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_396_4678"
                                      x1="13.5714"
                                      y1="1.78516"
                                      x2="1.21276"
                                      y2="3.5274"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#0193FD"></stop>
                                      <stop
                                        offset="1"
                                        stopColor="#133DD3"
                                      ></stop>
                                    </linearGradient>
                                  </defs>
                                </svg>
                                <span className="text">{item.address}</span>
                              </div>
                              <div className="eki">
                                <svg
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9.33333 1.83398C10.0175 1.83398 10.6755 2.09694 11.1712 2.56847C11.6669 3.03999 11.9625 3.68401 11.9967 4.36732L12 4.50065V12.5007C12 13.1848 11.737 13.8428 11.2655 14.3385C10.794 14.8342 10.15 15.1298 9.46667 15.164L9.33333 15.1673H6.66667C5.98251 15.1673 5.32451 14.9044 4.82879 14.4328C4.33307 13.9613 4.03754 13.3173 4.00333 12.634L4 12.5007V4.50065C4 3.81649 4.26296 3.1585 4.73448 2.66278C5.20601 2.16705 5.85003 1.87153 6.53333 1.83732L6.66667 1.83398H9.33333ZM8 10.5007C7.64638 10.5007 7.30724 10.6411 7.05719 10.8912C6.80714 11.1412 6.66667 11.4804 6.66667 11.834C6.66667 12.1876 6.80714 12.5267 7.05719 12.7768C7.30724 13.0268 7.64638 13.1673 8 13.1673C8.35362 13.1673 8.69276 13.0268 8.94281 12.7768C9.19286 12.5267 9.33333 12.1876 9.33333 11.834C9.33333 11.4804 9.19286 11.1412 8.94281 10.8912C8.69276 10.6411 8.35362 10.5007 8 10.5007ZM8 5.83398C7.37016 5.83398 6.76967 6.04897 6.28159 6.46356C5.79351 6.87814 5.50596 7.41194 5.44899 7.97248L5.44448 8.08398H10.5556L10.5511 7.97248C10.4941 7.41194 10.2066 6.87814 9.71853 6.46356C9.23045 6.04897 8.62984 5.83398 8 5.83398Z"
                                    fill="url(#paint0_linear_396_4679)"
                                  ></path>
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_396_4679"
                                      x1="12"
                                      y1="1.83398"
                                      x2="1.21276"
                                      y2="3.5274"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#0193FD"></stop>
                                      <stop
                                        offset="1"
                                        stopColor="#133DD3"
                                      ></stop>
                                    </linearGradient>
                                  </defs>
                                </svg>
                                <span className="text">{item.traffic}</span>
                              </div>
                              <div className="eki">
                                <svg
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.2422 11.6125C14.2366 11.597 14.228 11.5827 14.2169 11.5705C14.2057 11.5583 14.1923 11.5484 14.1773 11.5415C14.1624 11.5345 14.1462 11.5305 14.1297 11.5299C14.1132 11.5292 14.0967 11.5318 14.0813 11.5375L13.125 11.8859V3.47656C13.125 2.88906 12.65 2.41406 12.0625 2.41406H3.9375C3.35 2.41406 2.875 2.88906 2.875 3.47656V11.8859L1.91719 11.5375C1.90312 11.5328 1.88906 11.5297 1.875 11.5297C1.80625 11.5297 1.75 11.5859 1.75 11.6547V12.5859C1.75 12.6375 1.78281 12.6844 1.83281 12.7031L7.82812 14.8844C7.93906 14.925 8.05938 14.925 8.17031 14.8844L14.1672 12.7047C14.2172 12.6859 14.25 12.6391 14.25 12.5875V11.6562C14.25 11.6406 14.2469 11.6266 14.2422 11.6125ZM10.4328 5.72188L8.8125 8.71562H9.7125C9.78125 8.71562 9.8375 8.77187 9.8375 8.84062V9.26406C9.8375 9.33281 9.78125 9.38906 9.7125 9.38906H8.52031V9.99844H9.7125C9.78125 9.99844 9.8375 10.0547 9.8375 10.1234V10.5469C9.8375 10.6156 9.78125 10.6719 9.7125 10.6719H8.52031V11.6641C8.52031 11.7328 8.46406 11.7891 8.39531 11.7891H7.61562C7.54688 11.7891 7.49062 11.7328 7.49062 11.6641V10.6734H6.30312C6.23438 10.6734 6.17812 10.6172 6.17812 10.5484V10.125C6.17812 10.0563 6.23438 10 6.30312 10H7.49062V9.39062H6.30312C6.23438 9.39062 6.17812 9.33437 6.17812 9.26562V8.84219C6.17812 8.77344 6.23438 8.71719 6.30312 8.71719H7.19375L5.57031 5.72344C5.5375 5.66406 5.55938 5.5875 5.62031 5.55469C5.63906 5.54375 5.65937 5.53906 5.67969 5.53906H6.55C6.57303 5.53919 6.59558 5.54568 6.61515 5.55781C6.63473 5.56994 6.65057 5.58725 6.66094 5.60781L7.98438 8.24531H8.03594L9.35938 5.60781C9.37969 5.56562 9.42344 5.53906 9.47031 5.53906H10.3219C10.3906 5.53906 10.4469 5.59531 10.4484 5.6625C10.4484 5.68281 10.4422 5.70313 10.4328 5.72188Z"
                                    fill="url(#paint0_linear_871_5225)"
                                  />
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_871_5225"
                                      x1="1.75"
                                      y1="8.66445"
                                      x2="11.5447"
                                      y2="13.8121"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#1541DF" />
                                      <stop offset="1" stop-color="#0E30A7" />
                                    </linearGradient>
                                  </defs>
                                </svg>
                                <span className="text">{item.price}</span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                  </React.Fragment>
                );
              })
            }
          </MarkerClusterer>
        )}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default React.memo(MapCustom);
