import { encryptData, decryptData } from "./crypto";

// Lưu authToken và refreshToken vào localStorage
export const saveTokens = async (authToken, refreshToken, password) => {
  const { encryptedData: encryptedAuthToken, iv: authTokenIv } =
    await encryptData(authToken, password);
  const { encryptedData: encryptedRefreshToken, iv: refreshTokenIv } =
    await encryptData(refreshToken, password);

  localStorage.setItem(
    "authToken",
    JSON.stringify({
      data: Array.from(encryptedAuthToken),
      iv: Array.from(authTokenIv),
    })
  );
  localStorage.setItem(
    "refreshToken",
    JSON.stringify({
      data: Array.from(encryptedRefreshToken),
      iv: Array.from(refreshTokenIv),
    })
  );
};

// Lấy authToken và refreshToken từ localStorage
export const getTokens = async (password) => {
  const authToken = localStorage.getItem("authToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (!authToken || !refreshToken)
    return { authToken: null, refreshToken: null };

  const authTokenObj = JSON.parse(authToken);
  const refreshTokenObj = JSON.parse(refreshToken);

  const decryptedAuthToken = await decryptData(
    new Uint8Array(authTokenObj.data),
    new Uint8Array(authTokenObj.iv),
    password
  );
  const decryptedRefreshToken = await decryptData(
    new Uint8Array(refreshTokenObj.data),
    new Uint8Array(refreshTokenObj.iv),
    password
  );

  return { authToken: decryptedAuthToken, refreshToken: decryptedRefreshToken };
};
// Xóa authToken và refreshToken từ localStorage
export const clearTokens = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("infor_user");
  localStorage.removeItem("status_login");
};
