import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastCustom = ({ message, status, redirect, speed }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (message && status) {
      toast[status](message, {
        position: "top-right",
        autoClose: speed,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          if (redirect) {
            navigate(redirect);
          }
        },
      });
    }
  }, [message, status, redirect, speed, navigate]);

  return <ToastContainer limit={1} pauseOnHover />;
};

export default ToastCustom;
