import { gql } from "@apollo/client";

const LOGIN_REGISTER = gql`
  query Login_Register($idPage: ID!) {
    page(id: $idPage, idType: URI) {
      registerPage {
        contentPage {
          afterButton {
            description
            link
          }
          description
          submitButton
          title
          message {
            error
            errorMessage
            errorMessage8
            successMessage
          }
          inputForm {
            labelInput
            note
            placeholderInput
            typeInput
          }
          selectType {
            labelInput
            valueInput {
              valueInput1
              valueInput2
            }
          }
        }
      }
      loginPage {
        title
        submitButton
        description
        afterButton {
          description
          link
        }
        message {
          errorMessage
          successMessage
        }
        inputForm {
          labelInput
          placeholderInput
          typeInput
        }
      }
    }
  }
`;

export default LOGIN_REGISTER;
