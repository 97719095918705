import { gql } from "@apollo/client";

const REGISTER = gql`
  mutation REGISTER($input: RegisterUserInput!) {
    registerUser(input: $input) {
      user {
        id
        email
        firstName
        username
        userId
        nicename
        description
        locale
      }
    }
  }
`;

export default REGISTER;
