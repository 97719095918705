import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./page/homepage";
import Login from "./page/login";
import Register from "./page/register";
import DetailHouse from "./page/detail-house";
import "./assets/style/style-main.css";
import "./assets/style/styles.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "animate.css/animate.compat.css";
import Layout from "./global/Layout";
import User from "./page/user";
import Blog from "./page/blog";
import Houses from "./page/houses";
import GLOBAL_SITE from "./graphql/globalsite";
import { useQuery } from "@apollo/client";
import WishList from "./page/wishlist";
import { setTelegramInfor } from "./ulti/function";
import LayoutHeader from "./global/LayoutHeader";
import { useLanguage } from "./global/LanguageProvider";
import NotFound from "./page/not-found";
import MapCustom from "./page/map";

const App = () => {
  const [data, setData] = useState();
  const [footerData, setFooterData] = useState();
  const [headerData, setHeaderData] = useState();
  const { activeLanguage } = useLanguage();
  const { loading, error, refetch } = useQuery(GLOBAL_SITE, {
    variables: { input: activeLanguage ? activeLanguage : "vi" },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data) {
        document.title = data.globalSite.siteTitle;
        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon) {
          favicon.href = data.globalSite.siteIcon;
        }
        setTelegramInfor(data.telegramKey.telegramContent);
      }
      const content =
        data.footerWidget.footerContent.contentFooterField.fieldDataAccess.find(
          (item) => item.language.includes(activeLanguage)
        );
      const contentHeader =
        data.headerWidget.headerWidgetACF.headerWidgetContent.dataHeaderWidgetContent.find(
          (item) => item.language.includes(activeLanguage)
        );
      setData(data);
      setHeaderData(contentHeader);
      setFooterData(content.fieldFooterMain);
    },
    onError: (error) => {
      console.log("Query error:", error);
    },
  });
  useEffect(() => {
    refetch()
      .then((result) => {
        const content =
          result.data.footerWidget.footerContent.contentFooterField.fieldDataAccess.find(
            (item) => item.language.includes(activeLanguage)
          );
        const contentHeader =
          result.data.headerWidget.headerWidgetACF.headerWidgetContent.dataHeaderWidgetContent.find(
            (item) => item.language.includes(activeLanguage)
          );
        setData(result.data);
        setFooterData(content.fieldFooterMain);
        setHeaderData(contentHeader);
      })
      .catch((error) => {
        console.log("Error during refetch:", error);
      });
  }, [refetch, activeLanguage]);
  if (loading)
    return (
      <div className="load">
        <span className="loader"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;
  if (data) {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <Layout
                data={data}
                dataFooter={footerData}
                dataHeader={headerData}
              />
            }
          >
            <Route path="*" element={<NotFound />} />
            <Route
              path="/"
              element={
                <Homepage
                  dataGlobal={data}
                  uri={headerData && headerData.menus[0].value.url}
                />
              }
            />
            <Route
              path="/house/:slug"
              element={
                <DetailHouse
                  dataGlobal={data}
                  uri={data.customPageUris.detailHousePage}
                />
              }
            />
            <Route
              path="/houses"
              element={
                <Houses
                  uri={headerData && headerData.menus[1].value.url}
                  dataGlobal={data}
                  itemsPerPage={12}
                />
              }
            />
            <Route
              path="/wishlist"
              element={
                <WishList
                  uri={headerData && headerData.menus[1].value.url}
                  itemsPerPage={12}
                />
              }
            />
            <Route
              path="/user"
              element={
                <User uri={headerData && headerData.menus[3].value.url} />
              }
            />
            <Route path="/blog" element={<Blog itemsPerPage={6} />} />
          </Route>
          <Route element={<LayoutHeader data={data} dataHeader={headerData} />}>
            <Route
              path="/map"
              dataGlobal={data}
              element={
                <MapCustom uri={headerData && headerData.menus[1].value.url} />
              }
            />
          </Route>
          <Route
            path="/login"
            element={
              <Login
                uri={data.customPageUris.loginPage}
                dataGlobal={footerData}
              />
            }
          />
          <Route
            path="/register"
            element={
              <Register
                uri={data.customPageUris.registerPage}
                dataGlobal={footerData}
              />
            }
          />
          <Route
            path="/register/:id"
            element={
              <Register
                uri={data.customPageUris.registerPage}
                dataGlobal={footerData}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
};

export default App;
