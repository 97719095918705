import { format } from "date-fns";

export const GetDay = ({ dateString }) => {
  const date = new Date(dateString);
  const dayOptions = { day: "2-digit" };
  const day = new Intl.DateTimeFormat("en-GB", dayOptions).format(date);
  return day;
};
export const GetMonth = ({ dateString }) => {
  const date = new Date(dateString);
  const monthOptions = { month: "short" };
  const month = new Intl.DateTimeFormat("en-GB", monthOptions).format(date);
  return month;
};

export const FormatDate = ({ dateString }) => {
  const date = new Date(dateString);
  const formattedDate = format(date, "dd MMM yyyy");
  return formattedDate;
};
