import { gql } from "@apollo/client";

const FORMSEARCH = gql`
  query FormSearchGlobal {
    globalWidget {
      globalWidgetContent {
        formSearch {
          settingForm {
            max
            min
            step
          }
          globalWidgetField {
            language
            formSearchField {
              textList
              textMap
              textPlaceholderInput
              textHousePrice
              textForeignCurrencies
              textDesign
              textButtonSubmit
              backgroundForm {
                node {
                  mediaItemUrl
                }
              }
              labelInputCheckbox {
                value
              }
            }
          }
        }
      }
    }
  }
`;

export default FORMSEARCH;
