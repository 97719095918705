import { gql } from "@apollo/client";

const HOUSESBYMAP = gql`
  query HousesByMap(
    $address: String
    $maxPrice: Int
    $typeDesign: [String]
    $input: ID!
    $idPage: ID!
  ) {
    page(id: $idPage, idType: URI) {
      wishlistPage {
        contentPage {
          notFoundLabel
          labelContent {
            dataLabel
          }
        }
      }
    }
    wishlistHouses(userID: $input) {
      id
    }
    housesNearby(
      address: $address
      maxPrice: $maxPrice
      typeDesign: $typeDesign
    ) {
      price
      title
      distance
      address
      featuredImage
      id
      roomCode
      slug
      traffic
      ceremonyMoney
      deposit
      design
      houseCode
      managementFee
      pinColor
      location {
        lat
        lng
      }
    }
  }
`;
export default HOUSESBYMAP;
