import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";

const LayoutHeader = ({ data, dataHeader }) => {
  return (
    <div>
      <Header dataGlobal={data} dataHeader={dataHeader} />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default LayoutHeader;
