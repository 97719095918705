import { gql } from "@apollo/client";

const GLOBAL_SITE = gql`
  query GlobalSite($input: String) {
    customPageUris(language: $input) {
      blog
      loginPage
      wishlist
      detailHousePage
      registerPage
    }
    globalSite {
      logo
      siteIcon
      siteTitle
    }
    telegramKey {
      telegramContent {
        chatId
        token
      }
    }
    messageFormACF {
      contentMessageForm {
        ggMapKey
        messageForm {
          subject
          to
        }
      }
    }
    headerWidget {
      headerWidgetACF {
        headerWidgetContent {
          dataHeaderWidgetContent {
            language
            buttonContact {
              target
              title
              url
            }
            menus {
              value {
                target
                title
                url
              }
            }
          }
        }
      }
    }
    footerWidget {
      footerContent {
        contentFooterField {
          fieldDataAccess {
            language
            fieldFooterMain {
              address
              coppyRight
              fax
              hotline
              image {
                node {
                  mediaItemUrl
                }
              }
              socialIconList {
                socialIcon {
                  icon {
                    node {
                      mediaItemUrl
                    }
                  }
                  link {
                    target
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GLOBAL_SITE;
