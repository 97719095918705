import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { getTokens } from "./ulti/storage";
import { setStatusLogin } from "./ulti/function";

const httpLink = new HttpLink({
  uri: "https://graph.samuraichintai.com/index.php?graphql",
});
setStatusLogin(false);
const authLink = new ApolloLink(async (operation, forward) => {
  const token = await getTokens("123456");

  if (token.authToken !== null && token.refreshToken !== null) {
    setStatusLogin(true);
    operation.setContext({
      headers: {
        authorization: `Bearer ${token.refreshToken}`,
      },
    });
  }

  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
