const MessageForm = ({ email, fullname, dob, phone, gender }) => {
  const form = `
  Thành viên đăng ký 
  ============================================
  ${`Email: ${email}`}\n
  ${`Họ và tên: ${fullname}`}\n
  ${`Ngày sinh: ${dob}`}\n
  ${`Số điện thoại: ${phone}`}\n
  ${`Giới tính: ${gender}`}`.trim();

  return form;
};

export default MessageForm;
