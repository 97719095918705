import React, { useState } from "react";
import Eye from "../assets/img/Eye Show.svg";
import EyeOff from "../assets/img/iconmonstr-eye-off-thin.svg";

const InputCustom = ({ label, htmlFor, type, required, placeholder }) => {
  const [inputType, setInputType] = useState(type);
  const handleToggle = () => {
    setInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div className={`input-custom input-form ${htmlFor}`}>
      <label htmlFor={htmlFor}>
        {label}
        {required && <span className="red-required">*</span>}
      </label>
      <div className="input-type">
        <input
          type={inputType}
          name={htmlFor}
          id={htmlFor}
          placeholder={placeholder}
          required={required}
          onChange={handleChange}
        />
        {type === "password" && (
          <>
            {!value && (
              <label className="pass_placeholder">**************</label>
            )}
            <img
              loading="lazy"
              src={inputType === "password" ? Eye : EyeOff}
              alt="eye"
              onClick={handleToggle}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InputCustom;
