import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputCustom from "../global/InputCustom";
import Background from "../assets/img/background-auth-container.svg";
import { useMutation, useQuery } from "@apollo/client";
import LOGIN from "../graphql/login";
import ToastCustom from "../global/ToastCustom";
import { saveTokens } from "../ulti/storage";
import { getStatusLogin, setInforUser, setStatusLogin } from "../ulti/function";
import Language from "../global/Language";
import LOGIN_REGISTER from "../graphql/login-register";
const PASSWORD = "123456";

const Login = ({ dataGlobal, uri }) => {
  const formRef = useRef(null);
  const [toastMessage, setToastMessage] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  const [dataPage, setDataPage] = useState("");
  const statusLogin = getStatusLogin();
  const navigate = useNavigate();
  useEffect(() => {
    if (statusLogin) {
      navigate("/");
    }
  }, []);
  const { loading, error } = useQuery(LOGIN_REGISTER, {
    variables: { idPage: uri },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setDataPage(data.page.loginPage);
    },
    onError: (error) => {
      console.log("Query error:", error);
    },
  });
  const [login, { loading: loginLoading, error: loginError }] = useMutation(
    LOGIN,
    {
      onCompleted: async (dataResponse) => {
        const { authToken, refreshToken } = dataResponse.login;
        await saveTokens(authToken, refreshToken, PASSWORD);
        setStatusLogin(true);
        setInforUser(dataResponse.login.user);
        setToastMessage(dataPage && dataPage.message.successMessage);
        setToastStatus("success");
        setRedirectLink("/");
      },
      onError: (error) => {
        if (error.graphQLErrors) {
          setToastMessage(dataPage && dataPage.message.errorMessage);
          setToastStatus("error");
        }
      },
    }
  );
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());
    setToastMessage("");
    setToastStatus("");
    try {
      await login({
        variables: {
          input: {
            username: data["email-login"],
            password: data["password-login"],
          },
        },
      });
    } catch (error) {
      setToastMessage("An unexpected error occurred!");
      setToastStatus("error");
    }
  };
  if (loading)
    return (
      <div className="load">
        <span className="loader"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;
  if (dataPage) {
    return (
      <div
        className="login-page"
        style={{ backgroundImage: `url(${Background})` }}
      >
        {toastMessage !== "" && toastStatus !== "" && (
          <ToastCustom
            message={toastMessage}
            status={toastStatus}
            redirect={redirectLink}
            speed={redirectLink ? 50 : 2000}
          />
        )}

        <div className="container">
          <div className="header-login">
            <div className="col-logo">
              <Link to={"/"}>
                <img
                  loading="lazy"
                  src={dataGlobal.image.node.mediaItemUrl}
                  alt="Logo"
                  className="logo-site"
                  style={{ maxWidth: "129px" }}
                />
              </Link>
            </div>
            <ul className="wrap-menu">
              <Language />
            </ul>
          </div>
          <div className="form-login">
            <div className="content-form-login">
              <h1 className="title" style={{ textTransform: "capitalize" }}>
                {dataPage.title}
              </h1>
              <p className="description">{dataPage.description}</p>
              <form method="POST" ref={formRef} onSubmit={handleSubmit}>
                {dataPage.inputForm.map((item, index) => (
                  <InputCustom
                    key={index}
                    htmlFor={`${item.typeInput[0]}-login`}
                    label={item.labelInput}
                    type={item.typeInput[0]}
                    placeholder={item.placeholderInput}
                    required={true}
                  />
                ))}
                <div className="action">
                  <button type="submit" disabled={loginLoading}>
                    {dataPage.submitButton}
                  </button>
                </div>
                <div className="register-link">
                  <p>
                    {dataPage.afterButton.description}{" "}
                    <Link to={"/register"}>{dataPage.afterButton.link}</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Login;
