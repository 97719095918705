import { gql } from "@apollo/client";

const GET_DETAIL_HOUSE = gql`
  query GET_DETAIL_HOUSE($idHouse: ID!, $input: ID!, $idPage: ID!) {
    wishlistHouses(userID: $input) {
      id
      title
    }
    houses(idType: URI, id: $idHouse) {
      housesId
      slug
      title
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      housesAcf {
        acreage
        address
        ceremonyMoney
        deposit
        houseCode
        managementFee
        price
        traffic {
          trafficContent {
            data
          }
        }
        testTest {
          testAlum {
            testImage {
              node {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
    page(id: $idPage, idType: URI) {
      detailHouse {
        contentDataDetailHouse {
          labelInformation {
            dataValue
          }
          linkMessage {
            target
            title
            url
          }
          linkPhone {
            target
            title
            url
          }
        }
      }
    }
  }
`;

export default GET_DETAIL_HOUSE;
