import React, { useState } from "react";
import { useLanguage } from "./LanguageProvider";

const Language = () => {
  const { activeLanguage, setActiveLanguage } = useLanguage();
  const [dropdownActive, setDropdownActive] = useState(false);

  const languages = [
    {
      code: "vi",
      name: "VIE",
      svg: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_871_3012)">
            <path
              d="M14.2222 2.22266H1.77778C1.30628 2.22266 0.854097 2.40996 0.520699 2.74336C0.187301 3.07675 0 3.52894 0 4.00043L0 12.0004C0 12.4719 0.187301 12.9241 0.520699 13.2575C0.854097 13.5909 1.30628 13.7782 1.77778 13.7782H14.2222C14.6937 13.7782 15.1459 13.5909 15.4793 13.2575C15.8127 12.9241 16 12.4719 16 12.0004V4.00043C16 3.52894 15.8127 3.07675 15.4793 2.74336C15.1459 2.40996 14.6937 2.22266 14.2222 2.22266Z"
              fill="#DA251D"
            />
            <path
              d="M8.77912 7.12825L8.00001 4.73047L7.2209 7.12825H4.70001L6.73957 8.60958L5.96046 11.0074L8.00001 9.52558L10.0396 11.0074L9.26046 8.60958L11.3 7.12825H8.77912Z"
              fill="#FFFF00"
            />
          </g>
          <defs>
            <clipPath id="clip0_871_3012">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      code: "en",
      name: "ENG",
      svg: (
        /* SVG for ENG */ <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clipPath="url(#clip0_871_3022)">
            {" "}
            <path
              d="M14.2222 2.22266H1.77778C1.30628 2.22266 0.854097 2.40996 0.520699 2.74336C0.187301 3.07675 0 3.52894 0 4.00043L0 12.0004C0 12.4719 0.187301 12.9241 0.520699 13.2575C0.854097 13.5909 1.30628 13.7782 1.77778 13.7782H14.2222C14.6937 13.7782 15.1459 13.5909 15.4793 13.2575C15.8127 12.9241 16 12.4719 16 12.0004V4.00043C16 3.52894 15.8127 3.07675 15.4793 2.74336C15.1459 2.40996 14.6937 2.22266 14.2222 2.22266Z"
              fill="#EEEEEE"
            />{" "}
            <path
              d="M9.33333 2.22266H6.66667V6.6671H0V9.33377H6.66667V13.7782H9.33333V9.33377H16V6.6671H9.33333V2.22266Z"
              fill="#CE1124"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <clipPath id="clip0_871_3022">
              {" "}
              <rect width="16" height="16" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      ),
    },
    {
      code: "ja",
      name: "JPN",
      svg: (
        /* SVG for JPN */ <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <path
            d="M16 12.0004C16 12.4719 15.8127 12.9241 15.4793 13.2575C15.1459 13.5909 14.6937 13.7782 14.2222 13.7782H1.77778C1.30628 13.7782 0.854097 13.5909 0.520699 13.2575C0.187301 12.9241 0 12.4719 0 12.0004V4.00043C0 3.52894 0.187301 3.07675 0.520699 2.74336C0.854097 2.40996 1.30628 2.22266 1.77778 2.22266H14.2222C14.6937 2.22266 15.1459 2.40996 15.4793 2.74336C15.8127 3.07675 16 3.52894 16 4.00043V12.0004Z"
            fill="#EEEEEE"
          />{" "}
          <path
            d="M8.00003 11.1109C9.71825 11.1109 11.1111 9.718 11.1111 7.99978C11.1111 6.28156 9.71825 4.88867 8.00003 4.88867C6.28181 4.88867 4.88892 6.28156 4.88892 7.99978C4.88892 9.718 6.28181 11.1109 8.00003 11.1109Z"
            fill="#ED1B2F"
          />{" "}
        </svg>
      ),
    },
  ];

  const handleLanguageClick = (code) => {
    setActiveLanguage(code);
    setDropdownActive(false);
  };

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  return (
    <li
      className={`multi-language ${dropdownActive ? "active" : ""}`}
      onClick={toggleDropdown}
    >
      <ul className={`lang ${dropdownActive ? "show" : ""}`}>
        {languages.map((lang, index) => {
          let nthClass = "";
          if (activeLanguage !== lang.code) {
            const nonActiveIndex = languages
              .filter((l) => l.code !== activeLanguage)
              .indexOf(lang);
            nthClass = `nth-${nonActiveIndex + 1}`;
          }

          return (
            <li
              key={lang.code}
              className={`lg-item lg-${lang.code} ${
                activeLanguage === lang.code ? "active" : nthClass
              }`}
              onClick={() => handleLanguageClick(lang.code)}
            >
              <span className="flag">{lang.svg}</span>
              <span className="txt">{lang.name}</span>
              <input type="hidden" name={lang.value} value={lang.value} />
            </li>
          );
        })}
      </ul>
      <span className="down-icon">
        <svg
          width="15"
          height="8"
          viewBox="0 0 15 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.20698 7.70734C8.01945 7.89481 7.76514 8.00013 7.49998 8.00013C7.23481 8.00013 6.9805 7.89481 6.79298 7.70734L1.13598 2.05034C1.04047 1.9581 0.964284 1.84775 0.911875 1.72575C0.859466 1.60374 0.83188 1.47252 0.830726 1.33974C0.829572 1.20696 0.854874 1.07529 0.905155 0.952389C0.955436 0.829493 1.02969 0.71784 1.12358 0.623948C1.21747 0.530055 1.32913 0.455802 1.45202 0.405521C1.57492 0.35524 1.7066 0.329939 1.83938 0.331092C1.97216 0.332246 2.10338 0.359833 2.22538 0.412242C2.34739 0.464651 2.45773 0.540832 2.54998 0.636343L7.49998 5.58634L12.45 0.636343C12.6386 0.454185 12.8912 0.35339 13.1534 0.355669C13.4156 0.357947 13.6664 0.463116 13.8518 0.648524C14.0372 0.833932 14.1424 1.08474 14.1447 1.34694C14.1469 1.60914 14.0461 1.86174 13.864 2.05034L8.20698 7.70734Z"
            fill="white"
          />
        </svg>
      </span>
    </li>
  );
};

export default Language;
