import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

const Layout = ({ data, dataFooter, dataHeader }) => {
  return (
    <div>
      <Header dataGlobal={data} dataHeader={dataHeader} />
      <main>
        <Outlet />
      </main>
      <Footer dataFooter={dataFooter} />
    </div>
  );
};

export default Layout;
