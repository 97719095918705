import React from "react";
export const NumberFormatter = ({ numberString }) => {
  const number = parseInt(numberString, 10);
  const formattedNumber = number.toLocaleString("en-US");

  return <span>{formattedNumber}</span>;
};
export const formatDateDDMMYYYY = (dateStr) => {
  const [year, month, day] = dateStr.split("/");
  const fullYear = parseInt(year, 10) + 2000;
  return `${day}/${month}/${fullYear}`;
};

export const setInforUser = (data) => {
  localStorage.setItem("infor_user", JSON.stringify(data));
};
export const getInforUser = () => {
  let infor_user = null;

  if (localStorage.getItem("infor_user") !== "undefined") {
    infor_user = JSON.parse(localStorage.getItem("infor_user"));
  }
  return infor_user;
};
export const setTelegramInfor = (data) => {
  localStorage.setItem("infor_tele", JSON.stringify(data));
};
export const getTelegramInfor = () => {
  let infor_tele = null;

  if (localStorage.getItem("infor_tele") !== "undefined") {
    infor_tele = JSON.parse(localStorage.getItem("infor_tele"));
  }
  return infor_tele;
};
export const setStatusLogin = (data) => {
  localStorage.setItem("status_login", JSON.stringify(data));
};
export const getStatusLogin = () => {
  let status_login = null;

  if (localStorage.getItem("status_login") !== "undefined") {
    status_login = JSON.parse(localStorage.getItem("status_login"));
  }
  return status_login;
};
export const setSearchHistory = (data) => {
  sessionStorage.setItem("search_history", JSON.stringify(data));
};
export const getSearchHistory = () => {
  let search_history = null;

  if (sessionStorage.getItem("search_history") !== "undefined") {
    search_history = JSON.parse(sessionStorage.getItem("search_history"));
  }
  return search_history || [];
};
export const setDataSearch = (data) => {
  sessionStorage.setItem("data_search", JSON.stringify(data));
};
export const getDataSearch = () => {
  let data_search = null;

  if (sessionStorage.getItem("data_search") !== "undefined") {
    data_search = JSON.parse(sessionStorage.getItem("data_search"));
  }
  return data_search || undefined;
};
export const setActiveLanguageLocal = (data) => {
  if (
    localStorage.getItem("active_language") === "vie" ||
    localStorage.getItem("active_language") === "eng" ||
    localStorage.getItem("active_language") === "jpn"
  ) {
    localStorage.setItem("active_language", "vi");
  }else{
    localStorage.setItem("active_language", JSON.stringify(data));
  }
  
};
export const getActiveLanguageLocal = () => {
  let active_language = null;
  if (localStorage.getItem("active_language") !== "undefined") {
    active_language = JSON.parse(localStorage.getItem("active_language"));
  }
  return active_language || "vi";
};
