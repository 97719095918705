import { gql } from "@apollo/client";

const UPLOAD_AVATAR = gql`
  mutation UploadImage($file: String!, $title: String!, $userId: ID!) {
    uploadImage(input: { file: $file, title: $title, userId: $userId }) {
      message
    }
  }
`;
export default UPLOAD_AVATAR;
