import { gql } from "@apollo/client";

const PAGES = gql`
  query Pages($idPage: ID!, $input: ID!) {
    wishlistHouses(userID: $input) {
      id
      title
      address
      ceremony_money
      deposit
      management_fee
      price
      slug
      traffic
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
    page(id: $idPage, idType: URI) {
      homepage {
        contentPage {
          sectionAboutUs {
            description
            title
            button {
              target
              title
              url
            }
            image {
              node {
                mediaItemUrl
              }
            }
          }
          sectionApartment {
            title
            description
            background {
              node {
                mediaItemUrl
              }
            }
            apartments {
              nodes {
                ... on Houses {
                  id
                  housesId
                  title
                  featuredImage {
                    node {
                      mediaItemUrl
                    }
                  }
                  housesAcf {
                    address
                    traffic {
                      trafficContent {
                        data
                      }
                    }
                    price
                    roomCode
                    houseCode
                  }
                }
                slug
              }
            }
          }
          sectionBanner {
            imageBannerSlider {
              imageSlider {
                node {
                  mediaItemUrl
                }
              }
            }
          }
          sectionBlog {
            title
            description
            button {
              target
              title
              url
            }
            selectPosts {
              nodes {
                ... on Post {
                  id
                  featuredImage {
                    node {
                      mediaItemUrl
                    }
                  }
                  author {
                    node {
                      avatar {
                        url
                      }
                      name
                    }
                  }
                  title
                  slug
                  postsACF {
                    address
                  }
                  dateGmt
                }
              }
            }
          }
          sectionContact {
            backgroundSection {
              node {
                mediaItemUrl
              }
            }
            textButton
            title
            textInput {
              value
            }
          }
        }
      }
      userPage {
        dataPage {
          textLink
          labelSuggest
          labelHeadTable {
            labelHead
          }
          sectionProfile {
            labelField
          }
        }
      }
    }
    viewer {
      id
      email
      firstName
      username
      userId
      nicename
      description
      locale
    }
    avatarUrl(userId: $input)
    friendsList(userId: $input) {
      email
      id
      status
      fullname
      dob
    }
  }
`;

export default PAGES;
