const generateKey = async (password) => {
  // Hash the password to derive a key of the correct size (256-bit)
  const encoder = new TextEncoder();
  const passwordKey = await crypto.subtle.importKey(
    "raw",
    encoder.encode(password),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );

  // Derive the AES key from the password
  return crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: encoder.encode("some-salt"), // Use a random salt in practice
      iterations: 100000,
      hash: "SHA-256",
    },
    passwordKey,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"]
  );
};

const encryptData = async (data, password) => {
  const key = await generateKey(password);
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
  const encoder = new TextEncoder();

  const encryptedData = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv },
    key,
    encoder.encode(data)
  );

  return { encryptedData: new Uint8Array(encryptedData), iv };
};

const decryptData = async (encryptedData, iv, password) => {
  const key = await generateKey(password);
  const decryptedData = await crypto.subtle.decrypt(
    { name: "AES-GCM", iv },
    key,
    encryptedData
  );

  return new TextDecoder().decode(decryptedData);
};

export { encryptData, decryptData };
