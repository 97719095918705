import { gql } from "@apollo/client";

const REMOVE_WISHLIST = gql`
  mutation REMOVE_WISHLIST($input: RemoveHouseToWishlistInput!) {
    removeHouseToWishlist(input: $input) {
      success
    }
  }
`;

export default REMOVE_WISHLIST;
