import { gql } from "@apollo/client";

const ADD_WISHLIST = gql`
  mutation ADD_WISHLIST($input: AddHouseToWishlistInput!) {
    addHouseToWishlist(input: $input) {
      success
    }
  }
`;

export default ADD_WISHLIST;
