import React, { useEffect, useState } from "react";
import HomeIcon from "../assets/img/home_icon.svg";
import HomeIcon2 from "../assets/img/home2.svg";
import HomeIcon3 from "../assets/img/home3.svg";
import UserIcon1 from "../assets/img/Group 48096048.svg";
import UserIcon2 from "../assets/img/Group 48096046.svg";
import UserProfile from "../assets/img/user_profile.png";
import Camera from "../assets/img/clarity_camera-solid.svg";
import { useNavigate } from "react-router-dom";
import "../assets/style/user.css";
import {
  formatDateDDMMYYYY,
  getInforUser,
  getStatusLogin,
} from "../ulti/function";
import { clearTokens } from "../ulti/storage";
import ToastCustom from "../global/ToastCustom";
import { useMutation, useQuery } from "@apollo/client";
import { useLanguage } from "../global/LanguageProvider";
import UPLOAD_AVATAR from "../graphql/upload-avatar";
import PAGES from "../graphql/pages";

const User = ({ uri }) => {
  const navigate = useNavigate();
  const statusLogin = getStatusLogin();
  const inforUser = getInforUser();
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  const [file, setFile] = useState(null);
  const [uploadImage] = useMutation(UPLOAD_AVATAR);
  const copyToClipboard = () => {
    const textToCopy = document.querySelector(".datacoppy").textContent;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setToastMessage("Success!!!");
        setToastStatus("success");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    if (!statusLogin) {
      navigate("/login");
    }
  }, []);
  const [uploadReady, setUploadReady] = useState(false);

  useEffect(() => {
    if (uploadReady && file) {
      uploadImageHandler();
    }
  }, [uploadReady, file]);

  const uploadImageHandler = async () => {
    try {
      const { data } = await uploadImage({
        variables: {
          userId: inforUser && inforUser.userId,
          file: file.content,
          title: file.name,
        },
      });
      await refetch();
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setUploadReady(false); // Reset the flag
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile({
          content: reader.result.split(",")[1], // Get base64 content
          name: selectedFile.name,
        });
        setUploadReady(true); // Set flag to trigger upload
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const sigout = () => {
    clearTokens();
    navigate("/");
  };
  const { activeLanguage } = useLanguage();
  const [dataPage, setDataPage] = useState();
  const [dataFriends, setDataFriends] = useState();
  const { loading, error, refetch } = useQuery(PAGES, {
    variables: { input: inforUser && inforUser.userId, idPage: uri },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setAvatarUrl(data.avatarUrl);
      setDataPage(data.page.userPage.dataPage);
      setDataFriends(data.friendsList);
    },
    onError: (error) => {
      console.log("Query error:", error);
    },
  });
  // useEffect(() => {
  //   refetch()
  //     .then((result) => {
  //       setAvatarUrl(result.data.avatarUrl);
  //       setDataPage(result.data.page.userPage.dataPage);
  //       setDataFriends(result.data.friendsList);
  //     })
  //     .catch((error) => {
  //       console.log("Error during refetch:", error);
  //     });
  // }, [refetch, activeLanguage]);
  if (loading)
    return (
      <div className="load">
        <span className="loader"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="profile-home">
      <img className="home-icon" src={HomeIcon} alt="icon" loading="lazy" />
      <img className="home-icon" src={UserIcon1} alt="icon" loading="lazy" />
      <img className="home-icon" src={UserIcon2} alt="icon" loading="lazy" />
      {toastMessage !== "" && toastStatus !== "" && (
        <ToastCustom
          message={toastMessage}
          status={toastStatus}
          redirect={redirectLink}
          speed={redirectLink ? 500 : 2000}
        />
      )}
      <div className="container-profile">
        <div className="profile-layout">
          <div className="profile-main">
            <div className="profile__nav">
              {/* <img className="profile__nav__back" src={require('@/assets/svg/evaArrowBackFill0.svg').default} alt="back" /> */}
            </div>
            <div className="profile__info">
              <img className="icon-bg" loading="lazy" src={HomeIcon2} alt="icon" />
              <img className="icon-bg" loading="lazy" src={HomeIcon3} alt="icon" />
              <div className="profile__info__avatar">
                <img loading="lazy" src={avatarUrl ? avatarUrl : UserProfile} alt="user" />
                <div>
                  <img loading="lazy" src={Camera} alt="camera" />
                  <input
                    onChange={handleFileChange}
                    type="file"
                    accept="image/*"
                    className="camerafront"
                  />
                </div>
              </div>
              <div className="profile__info__desc">
                <div className="row-content">
                  <h6 className="title">
                    {dataPage && dataPage.sectionProfile[0].labelField}
                  </h6>
                  <h5 className="value">{inforUser && inforUser.firstName}</h5>
                </div>
                <div className="row-content">
                  <h6 className="title">
                    {dataPage && dataPage.sectionProfile[1].labelField}
                  </h6>
                  <h5 className="value">
                    {inforUser &&
                      formatDateDDMMYYYY(
                        inforUser.description
                          ? inforUser.description
                          : "24/06/30"
                      )}
                  </h5>
                </div>
                <div className="row-content">
                  <h6 className="title">
                    {dataPage && dataPage.sectionProfile[2].labelField}
                  </h6>
                  <h5 className="value">{inforUser && inforUser.nicename}</h5>
                </div>
                <div className="row-content">
                  <h6 className="title">
                    {dataPage && dataPage.sectionProfile[3].labelField}
                  </h6>
                  <h5 className="value">{inforUser && inforUser.email}</h5>
                </div>
              </div>
              <button
                onClick={sigout}
                className="btn btn-dim btn-outline-primary sigout"
              >
                <em className="icon ni ni-signout"></em>
                <p>Logout</p>
              </button>
            </div>
          </div>
          <div className="friend-list">
            <div className="friend-list__title">
              <div className="title__border"></div>
              <span className="title">{dataPage && dataPage.labelSuggest}</span>
              <div className="link-coppy">
                <div className="content-coppy">
                  <span>{dataPage && dataPage.textLink}</span>
                  <span className="datacoppy" onClick={copyToClipboard}>
                    {inforUser &&
                      `${window.location.host}/register/user-${inforUser.userId}`}
                  </span>
                </div>
                <span className="coppy" onClick={copyToClipboard}>
                  Copy link
                </span>
              </div>
            </div>
            <div className="friend-table">
              <table>
                <thead>
                  <tr>
                    {dataPage &&
                      dataPage.labelHeadTable.map((item, index) => (
                        <th key={index}>{item.labelHead}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(dataFriends) &&
                    dataFriends.length > 0 &&
                    dataFriends.map((item, index) => (
                      <tr
                        key={index}
                        className={item.status === "join" ? "tr-join" : ""}
                      >
                        <td>{index + 1}</td>
                        <td>{item.fullname}</td>
                        <td>{item.dob}</td>
                        <td>{item.email}</td>
                        <td>0.3%</td>
                        <td>
                          {item.status === "join" ? (
                            <span>Tham gia</span>
                          ) : (
                            <span>Chưa tham gia</span>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
