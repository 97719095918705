import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import LeftIcon from "../assets/img/left.svg";
import RightIcon from "../assets/img/right.svg";
import FirstIcon from "../assets/img/First.svg";
import LastIcon from "../assets/img/Last.svg";
import { getInforUser, getStatusLogin } from "../ulti/function";
import FormFind from "../global/FormFind";
import HeartDefault from "../assets/img/heart_default.svg";
import HeartActive from "../assets/img/heart_active.svg";
import ADD_WISHLIST from "../graphql/add-wishlist";
import REMOVE_WISHLIST from "../graphql/remove-wishlist";
import HOUSESBYMAP from "../graphql/house-by-map";
import Aos from "aos";

const Houses = ({ itemsPerPage, dataGlobal, uri }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const statusLogin = getStatusLogin();
  const navigate = useNavigate();
  const inforUser = getInforUser();
  const location = useLocation();
  const [active, setActive] = useState(location.state?.active || false);
  const { price, inputValue, designs } = location.state || {};
  const [dataPage, setDataPage] = useState();
  const [notFound, setNotFound] = useState();
  const [dataList, setDataList] = useState([]);
  const [dataListWishlist, setDataListWishlist] = useState([]);
  const [processingId, setProcessingId] = useState(null);
  useEffect(() => {
    Aos.init();
  }, []);
  const handleWishlistClick = (id, isWishlisted) => {
    setProcessingId(id);
    const buttonElement = document.querySelector(`[data-id="${id}"]`);
    if (buttonElement.classList.contains("wishlist-icon")) {
      buttonElement.classList.remove("wishlist-icon");
    } else {
      buttonElement.classList.add("wishlist-icon");
    }
    if (isWishlisted) {
      handleRemoveWishlist(id).finally(() => setProcessingId(null));
    } else {
      handleWishlist(id).finally(() => setProcessingId(null));
    }
  };
  useEffect(() => {
    if (!statusLogin) {
      navigate("/login");
    }
    window.scrollTo(0, 0);
  }, [statusLogin, navigate]);
  function convertToSlug(title) {
    return title
      .toLowerCase() // Chuyển tất cả các chữ thành chữ thường
      .normalize("NFD") // Chuẩn hóa các ký tự có dấu
      .replace(/[\u0300-\u036f]/g, "") // Loại bỏ các dấu
      .replace(/đ/g, "d") // Thay thế 'đ' bằng 'd'
      .replace(/[^a-z0-9\s-]/g, "") // Loại bỏ tất cả các ký tự không phải chữ cái, số và khoảng trắng
      .trim() // Loại bỏ khoảng trắng thừa ở đầu và cuối chuỗi
      .replace(/\s+/g, "-") // Thay thế khoảng trắng bằng dấu gạch ngang
      .replace(/-+/g, "-"); // Loại bỏ dấu gạch ngang liên tiếp
  }
  const slugArray = designs ? designs.map(convertToSlug) : [];
  const { loading, error, refetch } = useQuery(HOUSESBYMAP, {
    variables: {
      input: inforUser && inforUser.userId,
      address: inputValue,
      maxPrice: price,
      typeDesign: slugArray,
      idPage: uri,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setNotFound(data.page.wishlistPage.contentPage.notFoundLabel);
      setDataPage(data.page.wishlistPage.contentPage.labelContent);
      setDataList(data.housesNearby);
      setDataListWishlist(data.wishlistHouses);
    },
    onError: (error) => {
      console.log("Query error:", error);
    },
  });
  const [addWishlist, { loading: wishlistLoading, error: wishlistError }] =
    useMutation(ADD_WISHLIST, {
      onCompleted: async (data) => {
        try {
          // await refetch();
        } catch (refetchError) {
          console.log("Error during refetch:", refetchError);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  const [
    removeWishlist,
    { loading: removeWishlistLoading, error: removeWishlistError },
  ] = useMutation(REMOVE_WISHLIST, {
    onCompleted: async (data) => {
      // await refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  // useEffect(() => {
  //   refetch()
  //     .then((result) => {
  //       setDataList(result.data.housesNearby);
  //       setDataListWishlist(result.data.wishlistHouses);
  //     })
  //     .catch((error) => {
  //       console.log("Error during refetch:", error);
  //     });
  // }, [refetch]);
  if (loading)
    return (
      <div className="load">
        <span className="loader"></span>
      </div>
    );
  if (error) return <p>Error: {error.message}</p>;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = dataList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % dataList.length;
    setItemOffset(newOffset);
    setSelectedPage(event.selected);
  };

  const goToFirstPage = () => {
    setSelectedPage(0);
    setItemOffset(0);
  };

  const goToLastPage = () => {
    setSelectedPage(pageCount - 1);
    setItemOffset((pageCount - 1) * itemsPerPage);
  };
  const handleActive = () => {
    setActive(!active);
  };
  const handleWishlist = async (id) => {
    try {
      await addWishlist({
        variables: {
          input: {
            userId: inforUser && inforUser.userId,
            houseId: parseInt(id),
          },
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleRemoveWishlist = async (id) => {
    try {
      await removeWishlist({
        variables: {
          input: {
            userId: inforUser && inforUser.userId,
            houseId: parseInt(id),
          },
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="wrap-page bgr-right house-list">
      <div className="box-width section-top row-filter">
        <div className="btn-back" onClick={handleBack}>
          <svg
            width="28"
            height="24"
            viewBox="0 0 28 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.712 10.2869H5.38237L11.6047 2.81332C11.8956 2.46327 12.0356 2.01197 11.9938 1.55871C11.952 1.10545 11.7319 0.687351 11.3818 0.396397C11.0318 0.105443 10.5805 -0.0345367 10.1272 0.00725335C9.67396 0.0490434 9.25587 0.26918 8.96491 0.619234L0.39425 10.904C0.336588 10.9858 0.285025 11.0718 0.239978 11.1611C0.239978 11.2469 0.239979 11.2983 0.11999 11.384C0.0422939 11.5805 0.00161321 11.7897 0 12.0011C0.00161321 12.2124 0.0422939 12.4216 0.11999 12.6182C0.11999 12.7039 0.119989 12.7553 0.239978 12.841C0.285025 12.9304 0.336588 13.0163 0.39425 13.0981L8.96491 23.3829C9.12608 23.5764 9.3279 23.732 9.55602 23.8387C9.78415 23.9453 10.033 24.0004 10.2848 24C10.6853 24.0008 11.0734 23.8613 11.3818 23.6057C11.5554 23.4618 11.6989 23.2851 11.804 23.0857C11.9092 22.8862 11.974 22.668 11.9947 22.4435C12.0154 22.219 11.9916 21.9926 11.9247 21.7773C11.8578 21.562 11.749 21.362 11.6047 21.1888L5.38237 13.7152H25.712C26.1666 13.7152 26.6026 13.5346 26.9241 13.2131C27.2455 12.8917 27.4261 12.4557 27.4261 12.0011C27.4261 11.5465 27.2455 11.1105 26.9241 10.789C26.6026 10.4675 26.1666 10.2869 25.712 10.2869Z"
              fill="#212121"
            />
          </svg>
        </div>
        <div
          onClick={handleActive}
          className={`btn-filter ${active ? "active-search-form" : ""}`}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5728 30.3334C13.9328 30.3334 13.2928 30.1734 12.7061 29.8534C11.5195 29.1867 10.8127 28 10.8127 26.6534V19.5201C10.8127 18.8401 10.3727 17.84 9.95939 17.32L4.89274 12C4.05274 11.16 3.39941 9.69336 3.39941 8.61336V5.52006C3.39941 3.37339 5.02611 1.69336 7.09277 1.69336H24.8794C26.9194 1.69336 28.5728 3.34672 28.5728 5.38672V8.34672C28.5728 9.74672 27.7328 11.36 26.9328 12.1467C26.5461 12.5334 25.9061 12.5334 25.5195 12.1467C25.1328 11.76 25.1328 11.12 25.5195 10.7334C26.0128 10.24 26.5728 9.13339 26.5728 8.34672V5.38672C26.5728 4.45339 25.8127 3.69336 24.8794 3.69336H7.09277C6.14611 3.69336 5.39941 4.49339 5.39941 5.52006V8.61336C5.39941 9.1067 5.79942 10.0801 6.31942 10.6001L11.4528 16C12.1328 16.84 12.7994 18.2534 12.7994 19.5201V26.6534C12.7994 27.5334 13.3994 27.96 13.6661 28.1067C14.2394 28.4267 14.9195 28.4134 15.4528 28.0934L17.3194 26.8934C17.7061 26.6667 18.0794 25.9334 18.0794 25.44C18.0794 24.8934 18.5328 24.44 19.0794 24.44C19.6261 24.44 20.0794 24.8934 20.0794 25.44C20.0794 26.64 19.3327 28.0134 18.3861 28.5867L16.5328 29.7867C15.9328 30.1467 15.2528 30.3334 14.5728 30.3334Z"
              fill="url(#paint0_linear_1356_2736)"
            />
            <path
              d="M21.4268 23.0265C18.5202 23.0265 16.1602 20.6665 16.1602 17.7598C16.1602 14.8532 18.5202 12.4932 21.4268 12.4932C24.3335 12.4932 26.6935 14.8532 26.6935 17.7598C26.6935 20.6665 24.3335 23.0265 21.4268 23.0265ZM21.4268 14.4932C19.6268 14.4932 18.1602 15.9598 18.1602 17.7598C18.1602 19.5598 19.6268 21.0265 21.4268 21.0265C23.2268 21.0265 24.6935 19.5598 24.6935 17.7598C24.6935 15.9598 23.2268 14.4932 21.4268 14.4932Z"
              fill="url(#paint1_linear_1356_2736)"
            />
            <path
              d="M26.493 23.8271C26.2397 23.8271 25.9864 23.7337 25.7864 23.5337L24.4531 22.2004C24.0664 21.8137 24.0664 21.1737 24.4531 20.7871C24.8398 20.4004 25.4797 20.4004 25.8664 20.7871L27.1997 22.1204C27.5864 22.5071 27.5864 23.1471 27.1997 23.5337C27.0131 23.7204 26.7464 23.8271 26.493 23.8271Z"
              fill="url(#paint2_linear_1356_2736)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1356_2736"
                x1="3.39941"
                y1="16.0134"
                x2="24.1453"
                y2="25.5973"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1541DF" />
                <stop offset="1" stopColor="#0E30A7" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1356_2736"
                x1="16.1602"
                y1="17.7598"
                x2="24.4136"
                y2="22.0977"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1541DF" />
                <stop offset="1" stopColor="#0E30A7" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1356_2736"
                x1="24.1631"
                y1="22.1621"
                x2="26.7708"
                y2="23.5313"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1541DF" />
                <stop offset="1" stopColor="#0E30A7" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      {/* {toastMessage !== "" && toastStatus !== "" && (
        <ToastCustom
          message={toastMessage}
          status={toastStatus}
          redirect={redirectLink}
          speed={redirectLink !== "" ? 500 : 2000}
        />
      )} */}
      {currentItems.length > 0 ? (
        <>
          {active && (
            <FormFind
              dataGlobal={dataGlobal}
              bgColor={"transparent"}
              checkHome={false}
            />
          )}
          <h2 className="box-width address-search">{inputValue}</h2>
          <div className="list-house bgr-left">
            <div className="container box-width wrap-house">
              {currentItems.map((item, index) => {
                const isWishlisted = dataListWishlist.some(
                  (wishlist) => parseInt(wishlist.id) === parseInt(item.id)
                );
                return (
                  <div
                    className="item"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-offset="100"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="300"
                    key={index}
                  >
                    <div className="row-1">
                      <div className="image-featured">
                        <Link to={`/house/${item.slug}`}>
                          <img
                            src={item.featuredImage}
                            alt="House"
                            className=""
                            loading="lazy"
                            width="1367"
                            height="2048"
                          />
                        </Link>
                      </div>
                      <div className="info">
                        <h3 className="heading">
                          <Link to={`/house/${item.slug}`}>{item.houseCode}</Link>
                        </h3>
                        <div className="ie basho">
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.00014 1.78516C9.47777 1.78516 10.8949 2.37214 11.9397 3.41699C12.9846 4.46184 13.5716 5.87895 13.5716 7.35658C13.5716 9.71087 11.9173 12.2766 8.65157 15.0812C8.46998 15.2371 8.23846 15.3228 7.99907 15.3226C7.75968 15.3224 7.52831 15.2363 7.347 15.08L7.131 14.8926C4.00985 12.1612 2.42871 9.6583 2.42871 7.35658C2.42871 5.87895 3.0157 4.46184 4.06054 3.41699C5.10539 2.37214 6.5225 1.78516 8.00014 1.78516ZM8.00014 5.21373C7.43182 5.21373 6.88677 5.43949 6.48491 5.84136C6.08305 6.24322 5.85728 6.78826 5.85728 7.35658C5.85728 7.92491 6.08305 8.46995 6.48491 8.87181C6.88677 9.27368 7.43182 9.49944 8.00014 9.49944C8.56846 9.49944 9.11351 9.27368 9.51537 8.87181C9.91723 8.46995 10.143 7.92491 10.143 7.35658C10.143 6.78826 9.91723 6.24322 9.51537 5.84136C9.11351 5.43949 8.56846 5.21373 8.00014 5.21373Z"
                              fill="url(#paint0_linear_298_5469)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_298_5469"
                                x1="13.5716"
                                y1="1.78516"
                                x2="1.21288"
                                y2="3.5274"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#0193FD" />
                                <stop offset="1" stopColor="#133DD3" />
                              </linearGradient>
                            </defs>
                          </svg>
                          <span>{item.address}</span>
                        </div>
                        <div className="ie eki">
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_298_5473)">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.33333 1.83398C10.0175 1.83398 10.6755 2.09694 11.1712 2.56847C11.6669 3.03999 11.9625 3.68401 11.9967 4.36732L12 4.50065V12.5007C12 13.1848 11.737 13.8428 11.2655 14.3385C10.794 14.8342 10.15 15.1298 9.46667 15.164L9.33333 15.1673H6.66667C5.98251 15.1673 5.32451 14.9044 4.82879 14.4328C4.33307 13.9613 4.03754 13.3173 4.00333 12.634L4 12.5007V4.50065C4 3.81649 4.26296 3.1585 4.73448 2.66278C5.20601 2.16705 5.85003 1.87153 6.53333 1.83732L6.66667 1.83398H9.33333ZM8 10.5007C7.64638 10.5007 7.30724 10.6411 7.05719 10.8912C6.80714 11.1412 6.66667 11.4804 6.66667 11.834C6.66667 12.1876 6.80714 12.5267 7.05719 12.7768C7.30724 13.0268 7.64638 13.1673 8 13.1673C8.35362 13.1673 8.69276 13.0268 8.94281 12.7768C9.19286 12.5267 9.33333 12.1876 9.33333 11.834C9.33333 11.4804 9.19286 11.1412 8.94281 10.8912C8.69276 10.6411 8.35362 10.5007 8 10.5007ZM8 7.16732C7.64638 7.16732 7.30724 7.30779 7.05719 7.55784C6.80714 7.80789 6.66667 8.14703 6.66667 8.50065C6.66667 8.85427 6.80714 9.19341 7.05719 9.44346C7.30724 9.69351 7.64638 9.83398 8 9.83398C8.35362 9.83398 8.69276 9.69351 8.94281 9.44346C9.19286 9.19341 9.33333 8.85427 9.33333 8.50065C9.33333 8.14703 9.19286 7.80789 8.94281 7.55784C8.69276 7.30779 8.35362 7.16732 8 7.16732ZM8 3.83398C7.64638 3.83398 7.30724 3.97446 7.05719 4.22451C6.80714 4.47456 6.66667 4.8137 6.66667 5.16732C6.66667 5.52094 6.80714 5.86008 7.05719 6.11013C7.30724 6.36017 7.64638 6.50065 8 6.50065C8.35362 6.50065 8.69276 6.36017 8.94281 6.11013C9.19286 5.86008 9.33333 5.52094 9.33333 5.16732C9.33333 4.8137 9.19286 4.47456 8.94281 4.22451C8.69276 3.97446 8.35362 3.83398 8 3.83398Z"
                                fill="url(#paint0_linear_298_5473)"
                              />
                            </g>
                            <defs>
                              <linearGradient
                                id="paint0_linear_298_5473"
                                x1="4"
                                y1="8.50065"
                                x2="11.2764"
                                y2="10.7953"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#1541DF" />
                                <stop offset="1" stopColor="#0E30A7" />
                              </linearGradient>
                              <clipPath id="clip0_298_5473">
                                <rect
                                  width="16"
                                  height="16"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>{item.traffic[0]}</span>
                        </div>
                      </div>
                      <div className="wishlist">
                        <div
                          data-id={item.id}
                          key={item.id}
                          onClick={() =>
                            handleWishlistClick(item.id, isWishlisted)
                          }
                          className={`col-w wil ${
                            isWishlisted ? "wishlist-icon" : ""
                          }`}
                        >
                          {processingId === item.id &&
                          (removeWishlistLoading || wishlistLoading) ? (
                            <span className="loader load-ajax"></span>
                          ) : (
                            <>
                              <img
                                style={{ width: "26px" }}
                                className="heart heart-default"
                                src={HeartDefault}
                                loading="lazy"
                                alt="heart default"
                              />
                              <img
                                style={{ width: "26px" }}
                                className="heart heart-active"
                                src={HeartActive}
                                loading="lazy"
                                alt="heart active"
                              />
                            </>
                          )}
                        </div>
                        <div className="col-w inf">
                          <Link
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              zIndex: "2",
                            }}
                            to={`/house/${item.slug}`}
                          ></Link>
                          <span className="icon-house">
                            <svg
                              width="25"
                              height="26"
                              viewBox="0 0 25 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.7715 5.61031C12.6988 5.54077 12.6021 5.50195 12.5015 5.50195C12.4009 5.50195 12.3041 5.54077 12.2314 5.61031L3.24219 14.1977C3.20401 14.2342 3.17364 14.2781 3.15291 14.3267C3.13218 14.3753 3.12152 14.4276 3.12158 14.4804L3.12012 22.54C3.12012 22.9544 3.28474 23.3518 3.57776 23.6448C3.87079 23.9379 4.26822 24.1025 4.68262 24.1025H9.375C9.5822 24.1025 9.78091 24.0202 9.92743 23.8737C10.0739 23.7272 10.1563 23.5284 10.1563 23.3212V16.6806C10.1563 16.577 10.1974 16.4777 10.2707 16.4044C10.3439 16.3311 10.4433 16.29 10.5469 16.29H14.4531C14.5567 16.29 14.6561 16.3311 14.7293 16.4044C14.8026 16.4777 14.8438 16.577 14.8438 16.6806V23.3212C14.8438 23.5284 14.9261 23.7272 15.0726 23.8737C15.2191 24.0202 15.4178 24.1025 15.625 24.1025H20.3154C20.7298 24.1025 21.1273 23.9379 21.4203 23.6448C21.7133 23.3518 21.8779 22.9544 21.8779 22.54V14.4804C21.878 14.4276 21.8673 14.3753 21.8466 14.3267C21.8259 14.2781 21.7955 14.2342 21.7573 14.1977L12.7715 5.61031Z"
                                fill="url(#paint0_linear_298_5484)"
                              />
                              <path
                                d="M23.9702 12.5854L20.3179 9.09131V3.78906C20.3179 3.58186 20.2356 3.38315 20.0891 3.23664C19.9426 3.09012 19.7438 3.00781 19.5366 3.00781H17.1929C16.9857 3.00781 16.787 3.09012 16.6405 3.23664C16.4939 3.38315 16.4116 3.58186 16.4116 3.78906V5.35156L13.5835 2.64746C13.3189 2.37988 12.9253 2.22656 12.5 2.22656C12.0762 2.22656 11.6836 2.37988 11.419 2.64795L1.03322 12.5845C0.729511 12.8774 0.691425 13.3594 0.967792 13.6768C1.03719 13.7569 1.12217 13.822 1.21755 13.8682C1.31293 13.9145 1.41672 13.9408 1.5226 13.9457C1.62848 13.9505 1.73424 13.9337 1.83344 13.8964C1.93264 13.8591 2.02321 13.802 2.09963 13.7285L12.2315 4.04688C12.3042 3.97733 12.4009 3.93852 12.5015 3.93852C12.6021 3.93852 12.6988 3.97733 12.7715 4.04688L22.9043 13.7285C23.0536 13.8717 23.2535 13.9498 23.4603 13.9457C23.667 13.9417 23.8638 13.8559 24.0073 13.707C24.3071 13.3965 24.2822 12.8838 23.9702 12.5854Z"
                                fill="url(#paint1_linear_298_5484)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_298_5484"
                                  x1="3.12012"
                                  y1="14.8022"
                                  x2="17.764"
                                  y2="22.5639"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#1541DF" />
                                  <stop offset="1" stopColor="#0E30A7" />
                                </linearGradient>
                                <linearGradient
                                  id="paint1_linear_298_5484"
                                  x1="0.78125"
                                  y1="8.08652"
                                  x2="11.9168"
                                  y2="19.7908"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#1541DF" />
                                  <stop offset="1" stopColor="#0E30A7" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </span>
                          <span className="icon-i">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.33317 0.664062C3.65717 0.664062 0.666504 3.65473 0.666504 7.33073C0.666504 11.0067 3.65717 13.9974 7.33317 13.9974C11.0092 13.9974 13.9998 11.0067 13.9998 7.33073C13.9998 3.65473 11.0092 0.664062 7.33317 0.664062ZM7.33317 3.3974C7.50458 3.3974 7.67214 3.44822 7.81466 3.54346C7.95719 3.63869 8.06827 3.77404 8.13387 3.9324C8.19946 4.09077 8.21662 4.26502 8.18318 4.43314C8.14974 4.60126 8.0672 4.75568 7.946 4.87689C7.82479 4.99809 7.67037 5.08064 7.50225 5.11408C7.33413 5.14752 7.15987 5.13035 7.00151 5.06476C6.84315 4.99916 6.70779 4.88808 6.61256 4.74556C6.51733 4.60303 6.4665 4.43547 6.4665 4.26406C6.4665 4.03421 6.55781 3.81377 6.72034 3.65124C6.88288 3.4887 7.10332 3.3974 7.33317 3.3974ZM8.93317 10.9307H5.99984C5.85839 10.9307 5.72273 10.8745 5.62271 10.7745C5.52269 10.6745 5.4665 10.5388 5.4665 10.3974C5.4665 10.2559 5.52269 10.1203 5.62271 10.0203C5.72273 9.92025 5.85839 9.86406 5.99984 9.86406H6.93317V6.93073H6.39984C6.25839 6.93073 6.12273 6.87454 6.02271 6.77452C5.92269 6.6745 5.8665 6.53884 5.8665 6.3974C5.8665 6.25595 5.92269 6.12029 6.02271 6.02027C6.12273 5.92025 6.25839 5.86406 6.39984 5.86406H7.4665C7.60795 5.86406 7.74361 5.92025 7.84363 6.02027C7.94365 6.12029 7.99984 6.25595 7.99984 6.3974V9.86406H8.93317C9.07462 9.86406 9.21027 9.92025 9.31029 10.0203C9.41031 10.1203 9.4665 10.2559 9.4665 10.3974C9.4665 10.5388 9.41031 10.6745 9.31029 10.7745C9.21027 10.8745 9.07462 10.9307 8.93317 10.9307Z"
                                fill="url(#paint0_linear_298_5483)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_298_5483"
                                  x1="0.666504"
                                  y1="7.33073"
                                  x2="11.1139"
                                  y2="12.8217"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#1541DF" />
                                  <stop offset="1" stopColor="#0E30A7" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row-2">
                      <div className="expense yachin">
                        <span className="lb">
                          {dataPage ? dataPage[0].dataLabel : "Tiền nhà"}
                        </span>
                        <span className="amount">{item.price}</span>
                      </div>
                      <div className="expense kanri">
                        <span className="lb">
                          {dataPage ? dataPage[1].dataLabel : "Phí quản lý"}
                        </span>
                        <span className="amount">{item.managementFee}</span>
                      </div>
                      <div className="expense yokin">
                        <span className="lb">
                          {dataPage ? dataPage[2].dataLabel : "Tiền cọc"}
                        </span>
                        <span className="amount">{item.deposit}</span>
                      </div>
                      <div className="expense iwaikin">
                        <span className="lb">
                          {dataPage ? dataPage[3].dataLabel : "Tiền lễ"}
                        </span>
                        <span className="amount">{item.ceremonyMoney}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {pageCount > 1 && (
            <div className="pagination-buttons">
              <button onClick={goToFirstPage}>
                <img loading="lazy" src={FirstIcon} alt="icon" />
              </button>
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <img loading="lazy" src={RightIcon} alt="Next page" />
                }
                onPageChange={handlePageClick}
                forcePage={selectedPage} // Ensure the selected page is active
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel={
                  <img loading="lazy" src={LeftIcon} alt="Previous page" />
                }
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="active"
              />
              <button onClick={goToLastPage}>
                <img loading="lazy" src={LastIcon} alt="icon" />
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          {active && (
            <FormFind
              dataGlobal={dataGlobal}
              bgColor={"transparent"}
              checkHome={false}
            />
          )}
          <h2 className="not-found">{notFound}</h2>
        </>
      )}
    </div>
  );
};
export default Houses;
