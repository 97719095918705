import { gql } from "@apollo/client";

const SENDMAIL = gql`
  mutation SendMail($input: SendEmailInput!) {
    sendEmail(input: $input) {
      message
      success
    }
  }
`;
export default SENDMAIL;
