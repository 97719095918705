import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Language from "./Language";
import { getStatusLogin } from "../ulti/function";

const Header = ({ dataGlobal, dataHeader }) => {
  const [isShown, setIsShown] = useState(false);
  const [menu, menuUpdated] = useState([]);
  const location = useLocation();
  const menuRef = useRef(null);
  const [headerClass, setHeaderClass] = useState("");
  const prevScrollY = useRef(0);

  const handleClick = (e) => {
    e.stopPropagation();
    setIsShown(!isShown);
  };

  const handleMenuItemClick = () => {
    setIsShown(false);
  };

  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsShown(false);
    }
  };

  useEffect(() => {
    if (isShown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isShown]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const headerHeight = document.querySelector("header") && document.querySelector("header").offsetHeight;
      if (scrollY > headerHeight) {
        if (scrollY > prevScrollY.current) {
          setHeaderClass("fixed-menu");
          setIsShown(false);
        } else {
          setHeaderClass("fixed-menu show");
        }
      } else {
        setHeaderClass("");
      }

      prevScrollY.current = scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const statusLogin = getStatusLogin();
  const navigate = useNavigate();
  const handleContact = () => {
    if (!statusLogin) {
      navigate("/login");
    } else {
      window.location.href = dataHeader && dataHeader.buttonContact.url;
    }
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    if (dataHeader) {
      const menusUri = ["/", "wishlist", "blog", "user"];
      const updatedMenus = dataHeader.menus.map((menu, index) => ({
        ...menu,
        uri: menusUri[index],
      }));
      menuUpdated(updatedMenus);
    }
  }, [dataHeader]);
  return (
    <header className={headerClass}>
      <div className="wrap-header">
        <div className="col-header">
          <div className="col-logo">
            <Link to={"/"}>
              <img
                loading="lazy"
                src={dataGlobal.globalSite.logo}
                alt="Logo"
                className="logo-site"
                width="100"
                height="100"
              />
            </Link>
          </div>
          <div className={`col-menu ${isShown ? "show" : ""}`} ref={menuRef}>
            <div className="menu-mobile" onClick={handleClick}>
              <span className="line l1"></span>
              <span className="line l2"></span>
              <span className="line l3"></span>
            </div>
            <ul className="wrap-menu">
              {menu.length > 0 &&
                menu.map((item, index) => (
                  <li
                    key={index}
                    className={location.pathname === item.uri ? "active" : ""}
                    onClick={handleMenuItemClick}
                  >
                    <Link to={item.uri}>{item.value.title}</Link>
                  </li>
                ))}
              {isShown && (
                <li
                  className={
                    location.pathname === dataHeader.buttonContact.url
                      ? "active"
                      : ""
                  }
                  onClick={handleMenuItemClick}
                >
                  <button
                    className="btn btn-contact btn-contact-mb"
                    onClick={handleContact}
                  >
                    {statusLogin
                      ? dataHeader && dataHeader.buttonContact.title
                      : "Login"}
                  </button>
                </li>
              )}
              <Language />
            </ul>
          </div>
          <div className="col-contact">
            <button className="btn btn-contact" onClick={handleContact}>
              {statusLogin
                ? dataHeader && dataHeader.buttonContact.title
                : "Login"}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
