import React from "react";
import { Link } from "react-router-dom";
const Footer = ({ dataFooter }) => {
  if (dataFooter) {
    return (
      <footer>
        <div className="ft-content">
          <div className="wrap-footer">
            <div className="rw row-logo">
              <img
                loading="lazy"
                src={dataFooter.image.node.mediaItemUrl}
                alt="Logo"
                className="logo-footer"
                width="96"
                height="121"
              />
            </div>
            <div className="rw row-address">
              <div className="add">
                <span className="icon" style={{ marginRight: "6px" }}>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_807_2915)">
                      <path
                        d="M11.2619 11.2832L10.6452 12.4427L13.6644 11.9491C13.0552 11.6435 12.2304 11.4251 11.2619 11.2832ZM3.48427 11.536C2.08587 11.9048 1.19995 12.5091 1.19995 13.4067C1.20053 13.6 1.24277 13.7908 1.32379 13.9662L6.16939 13.1742L3.48427 11.536ZM14.6864 12.8832L7.94235 13.9824L9.72091 16.0058C12.6416 15.7442 14.8 14.8112 14.8 13.4067C14.8 13.2195 14.7596 13.0454 14.6864 12.8832ZM6.80283 14.172L2.24603 14.9168C3.45051 15.663 5.57643 16.0832 7.99995 16.0832C8.14683 16.0832 8.29035 16.079 8.43499 16.076L6.80283 14.172Z"
                        fill="white"
                      />
                      <path
                        d="M8.00028 0.0839844C5.39228 0.0839844 3.25244 2.21726 3.25244 4.81934C3.25244 5.81966 3.57036 6.7515 4.10716 7.51774L7.1846 12.8377L7.19772 12.8549C7.31916 13.0134 7.43772 13.1385 7.57692 13.2305C7.71612 13.3227 7.88892 13.3777 8.05852 13.3608C8.3974 13.3269 8.60492 13.0875 8.80188 12.8206L8.81244 12.8069L12.2099 7.0243L12.2118 7.02063C12.2915 6.87663 12.3504 6.73006 12.4003 6.58734C12.6295 6.02607 12.7474 5.42561 12.7475 4.81934C12.7475 2.21726 10.6083 0.0839844 8.00028 0.0839844ZM8.00028 2.81118C9.13164 2.81118 10.0147 3.69262 10.0147 4.81918C10.0147 5.94606 9.13164 6.82719 8.00028 6.82719C6.86876 6.82719 5.98524 5.9459 5.98524 4.81918C5.98524 3.69278 6.86892 2.81118 8.00028 2.81118Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_807_2915">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(0 0.0839844)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                {dataFooter.address}
              </div>
              <div className="phone">
                <span className="icon" style={{ marginRight: "2px" }}>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.91333 7.27732C5.87333 9.16398 7.42 10.704 9.30667 11.6707L10.7733 10.204C10.9533 10.024 11.22 9.96398 11.4533 10.044C12.2 10.2907 13.0067 10.424 13.8333 10.424C14.2 10.424 14.5 10.724 14.5 11.0907V13.4173C14.5 13.784 14.2 14.084 13.8333 14.084C7.57333 14.084 2.5 9.01065 2.5 2.75065C2.5 2.38398 2.8 2.08398 3.16667 2.08398H5.5C5.86667 2.08398 6.16667 2.38398 6.16667 2.75065C6.16667 3.58398 6.3 4.38398 6.54667 5.13065C6.62 5.36398 6.56667 5.62398 6.38 5.81065L4.91333 7.27732Z"
                      fill="white"
                    />
                  </svg>
                </span>
                {dataFooter.hotline}
                <span className="icon" style={{ marginLeft: "4px" }}>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1667 6.08333H12.5V4.08333C12.5 3.35 11.9 2.75 11.1667 2.75H7.16671C6.43337 2.75 5.83337 3.35 5.83337 4.08333V13.4167H13.8334C14.5667 13.4167 15.1667 12.8167 15.1667 12.0833V8.08333C15.1667 6.97667 14.2734 6.08333 13.1667 6.08333ZM7.16671 4.08333H11.1667V6.08333H7.16671V4.08333ZM9.83337 11.4167H7.16671V8.08333H9.83337V11.4167ZM11.1667 11.4167C10.8 11.4167 10.5 11.1167 10.5 10.75C10.5 10.3833 10.8 10.0833 11.1667 10.0833C11.5334 10.0833 11.8334 10.3833 11.8334 10.75C11.8334 11.1167 11.5334 11.4167 11.1667 11.4167ZM11.1667 9.41667C10.8 9.41667 10.5 9.11667 10.5 8.75C10.5 8.38333 10.8 8.08333 11.1667 8.08333C11.5334 8.08333 11.8334 8.38333 11.8334 8.75C11.8334 9.11667 11.5334 9.41667 11.1667 9.41667ZM13.1667 11.4167C12.8 11.4167 12.5 11.1167 12.5 10.75C12.5 10.3833 12.8 10.0833 13.1667 10.0833C13.5334 10.0833 13.8334 10.3833 13.8334 10.75C13.8334 11.1167 13.5334 11.4167 13.1667 11.4167ZM13.1667 9.41667C12.8 9.41667 12.5 9.11667 12.5 8.75C12.5 8.38333 12.8 8.08333 13.1667 8.08333C13.5334 8.08333 13.8334 8.38333 13.8334 8.75C13.8334 9.11667 13.5334 9.41667 13.1667 9.41667ZM3.50004 5.41667C3.05801 5.41667 2.63409 5.59226 2.32153 5.90482C2.00897 6.21738 1.83337 6.64131 1.83337 7.08333V12.4167C1.83337 12.8587 2.00897 13.2826 2.32153 13.5952C2.63409 13.9077 3.05801 14.0833 3.50004 14.0833C3.94207 14.0833 4.36599 13.9077 4.67855 13.5952C4.99111 13.2826 5.16671 12.8587 5.16671 12.4167V7.08333C5.16671 6.86446 5.1236 6.64774 5.03984 6.44553C4.95608 6.24332 4.83332 6.05959 4.67855 5.90482C4.52379 5.75006 4.34006 5.62729 4.13785 5.54353C3.93564 5.45978 3.71891 5.41667 3.50004 5.41667Z"
                      fill="white"
                    />
                  </svg>
                </span>
                {dataFooter.fax}
              </div>
            </div>
            <div className="rw row-social">
              {dataFooter.socialIconList.socialIcon.map((item, index) => (
                <div className="sc" key={index}>
                  <Link to={item.link.url} target={item.link.target}>
                    <img
                      src={item.icon.node.mediaItemUrl}
                      alt="icon"
                      loading="lazy"
                    />
                  </Link>
                </div>
              ))}
            </div>
            <p className="rw copyright">{dataFooter.coppyRight}</p>
          </div>
        </div>
      </footer>
    );
  }
};
export default Footer;
