import { gql } from "@apollo/client";

const ADD_FRIEND = gql`
  mutation AddFriend($input: AddFriendInput!) {
    addFriend(input: $input) {
      success
      message
    }
  }
`;

export default ADD_FRIEND;
